<script>
import Layout from "../../layouts/main";
import * as moment from "moment";
import Multiselect from 'vue-multiselect';
import "vue2-datepicker/index.css";
import DownloadBtn from '../../../components/download-btn.vue';
import Filters from '../../../components/filters.vue';
import PerPage from '../../../components/per-page.vue';
import SearchBy from '../../../components/search-by.vue';

import {
  FETCH_PAYMENT_PAGE_LIST,
  CANCEL_PAYMENT_PAGE,
  EXPORT_PAYMENT_PAGE_LIST,
  FETCH_DETAILS_FOR_ALL_SUB_USERS
} from "@/state/actions.type";

export default {
  components: {
    Layout,
    Multiselect,
    DownloadBtn,
    PerPage,
    Filters,
    SearchBy
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      createdByFilter: null,
      pageOptions: [10, 25, 50, 100],
      filters: {
        page_type: 'NORMAL'
      },
      searchBy: {
        key: "Merchant Order Ref",
        value: ""
      },
      from: "",
      to: "",
      fields: [
        { key: "created_at", sortable: false, label: "views.payment_pages.table.fields.created_at" },
        { key: "title", sortable: false, label: "views.payment_pages.table.fields.title", tdClass: "text-chai" },
        { key: "page_link", sortable: false, label: "views.payment_pages.table.fields.page_url" },
        { key: "stock_sold_quantity", sortable: false, label: "Unit Sold" },
        { key: "status", sortable: false, label: "views.payment_pages.table.fields.status" },
        // { key: "merchant_email_address", sortable: false, label: "views.payment_pages.table.fields.created_by" , thClass: "",tdClass: "" },
        { key: "show_details", sortable: false, label: '' },
      ],
      statusOptions: ['Created', 'Cancelled']
    };
  },
  filters: {
    date(value) {
      return moment(value).format("DD MMM YYYY");
    },
    time(value) {
      return 'at ' + moment(value).format("hh:mm A");
    },
  },

  created() {
    this.fetchPaymentPageList();
    if(this.isGlobalUser && !this.isMasterMerchant) {
      this.fetchSubUsersList();
    }
  },

  computed: {
    paymentPagesDataStore() {
      return this.$store.state.paymentPages.content;
    },

    isFetchingTransactions() {
      return this.$store.state.paymentPages.isFetchingData;
    },

    rows() {
      return this.$store.state.paymentPages.total_elements;
    },

    title() {
      return this.$t('views.payment_pages.title');
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    items() {
      return [
        {
          text: this.$t('views.payment_pages.payments'),
          active: true,
        },
        {
          text: this.$t('views.payment_pages.title'),
          active: true,
        }
      ];
    },

    isGlobalUser() {
      return this.$store.state.auth.currentUser.is_global_user;
    },

    subUserList() {
      return this.$store.state.accounts.subUserData || [];
    },
  },

  methods: {
    createPaymentPage() {
      this.$router.push({ path: '/settings/payment-pages/config'})
    },
    sendToDetailsPage(item) {
      this.$router.push({ path: '/settings/payment-pages-details', query: { pageRef: item.page_ref, key: this.isMasterMerchant ? item.chaipaykey : "" }})
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_pages.copy_success'),
        closeOnClick: true,
      });
    },
    fetchPaymentPageList() {
      if(this.isGlobalUser && !this.isMasterMerchant && this.createdByFilter) {
        this.filters.created_by = this.createdByFilter.uuid;
      } else {
        delete this.filters.created_by;
      }

      if(this.searchBy.value) {
        this.filters.title = this.searchBy.value;
      } else {
        delete this.filters.title;
      }

      this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_LIST}`, {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        from: this.from ? moment(this.from).toJSON() : "",
        to: this.to ? moment(this.to).toJSON() : ""
      });
    },
    resetAdvanceFilters() {
      if(this.from || this.to || (this.filters.status && this.filters.status.length > 0 || this.createdByFilter)) {
        this.filters = {
          page_type: 'NORMAL'
        };
        this.from = "";
        this.to = "";
        this.createdByFilter = null;
        this.fetchPaymentPageList();
      }
    },

    deletePage(pageRef) {
      this.$store.dispatch(`paymentPages/${CANCEL_PAYMENT_PAGE}`, pageRef).then(() => {
        this.fetchPaymentPageList();
      });
    },
    downloadExcel() {
      this.$store.dispatch(`paymentPages/${EXPORT_PAYMENT_PAGE_LIST}`, {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        from: this.from ? moment(this.from).format("YYYY-MM-DD HH:mm:ss") : "",
        to: this.to ? moment(this.to).format("YYYY-MM-DD HH:mm:ss") : ""
      });
    },
    notAfterToday(date) {
      if(this.to) {
        return date > new Date() || date >= new Date(this.to);
      }
      return date > new Date();
    },
    fetchSubUsersList() {
      this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`);
    },
    dateChanged(value) {
      this.from = value[0];
      this.to = value[1];

      this.fetchPaymentPageList();
    },
    toggleFilter() {
      this.$root.$emit('bv::toggle::collapse', 'payment-pages-filter-collapse');
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <div class="d-flex mb-4">
      <router-link
        v-if="!isMasterMerchant"
        class="btn btn-primary ml-auto"
        to="/settings/payment-pages/config"
        style="padding: 12px 16px;font-weight: 400;font-size: 14px;line-height: 20px;"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style="margin-right: 8px;">
          <path d="M5 10H15M10 15V5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>{{ $t("views.payment_pages.create") }}
      </router-link>
    </div>

    <filters class="mb-4" @date-change="dateChanged" @toggle-filter="toggleFilter" />

    <b-collapse id="payment-pages-filter-collapse" accordion="my-accordion" @hidden="resetAdvanceFilters" @show="resetAdvanceFilters" style="padding: 25px;" class="common-border bg-white">
      <div class="row">
        <div v-if="isGlobalUser && !isMasterMerchant" class="col-md-4 mb-4">
          <label class="">Created By</label>
          <multiselect
            v-model="createdByFilter"
            :options="subUserList"
            placeholder="Search User"
            track-by="uuid"
            label="email_address"
            :searchable="true"
            :taggable="true"
            name="created_by"
          ></multiselect>
        </div>
  
        <div class="col-md-4 mb-4">
          <label class="">Status</label>
          <multiselect
            v-model="filters.status"
            :options="statusOptions"
            :placeholder="$t('common.search_status')"
            :searchable="true"
            :multiple="true"
            name="status"
          ></multiselect>
        </div>
      </div>
      
      <div class="d-flex justify-content-between">
        <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
        <div>
          <b-btn variant="primary" @click="toggleFilter" class="mr-2 bg-white text-dark cancel">Cancel</b-btn>
          <b-btn variant="primary" @click="fetchPaymentPageList" class="btn-padding">Apply</b-btn>
        </div>
      </div>
    </b-collapse>

    <div class="d-flex justify-content-between mb-3 pt-2  ">
      <!-- :options="searchByOptions" -->
      <search-by v-model="searchBy" @search-by-clicked="fetchPaymentPageList" :hideSearchButton="true" />
      <download-btn @download-button-clicked="downloadExcel()" />
    </div>

    <b-table
      class="table-custom"
      :items="paymentPagesDataStore"
      :fields="fields"
      responsive="sm"
      sticky-header="500px"
      show-empty
      no-local-sorting
      :busy="isFetchingTransactions"
      :empty-text="$t('common.no-records')"
      hover
    >
      <template v-slot:head()="data">
        {{ $t(data.label) }}
      </template>
      <template v-slot:cell(page_link)="{ value, item }">
        <span v-show="!value">{{ $t('common.na') }}</span>
        <div v-show="value" class="d-flex align-items-center">
          <a class="d-flex pr-1" target="_black" v-b-tooltip.hover.right :title="item.page_ref" :href="value">
            <p class="text-truncate mb-0 mr-1" style="max-width: 11.5rem;">{{ item.page_ref }}</p>

            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g clip-path="url(#clip0_3810_38356)">
                <path d="M9.75 8.25L15.9 2.1M16.5 5.1V1.5H12.9M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_3810_38356">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
          <b-btn
            v-show="value"
            v-clipboard:copy="value"
            v-clipboard:success="onCopySuccess"
            title="copy"
            class="ml-2 common-bg-30 border-0"
            style="padding: 7px; border-radius: var(--8, 8px);"
            size="sm">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g opacity="0.3" clip-path="url(#clip0_3810_38358)">
                <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_3810_38358">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </b-btn>
        </div>
      </template>
      <template v-slot:cell(created_at)="row">
        <p class="table-date">{{ row.value | date }}</p>
        <p class="table-time">{{ row.value | time }}</p>
      </template>
      <template v-slot:cell(status)="{ value }">
        <div
          class="badge custom-status"
          :class="{
            'badge-soft-success': `${value}` === 'Created',
            'badge-soft-secondary': `${value}` === 'Cancelled',
          }"
        >
          {{ value === 'Created' ?  'Active' : 'Inactive' }}
        </div>
      </template>
      <template #cell(show_details)="row">
        <b-dropdown class="show_details" size="sm p-0" variant="white bg-transparent" dropleft>
          <template v-slot:button-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
            </svg>
          </template>
          <b-dropdown-item-button @click="sendToDetailsPage(row.item)">{{ $t("views.payment_pages.page_details") }}</b-dropdown-item-button>
          <b-dropdown-item-button variant="danger" style="color: #DE3535 !important;" v-if="row.item.status !== 'Cancelled'" @click="deletePage(row.item.page_ref)">Deactivate Page</b-dropdown-item-button>
        </b-dropdown>
      </template>
    </b-table>

    <div class="d-flex justify-content-between flex-wrap" style="gap: 10px;">
      <per-page
        v-model="perPage"
        :currentPage="currentPage"
        :totalRows="rows"
        :tableRowsCount="paymentPagesDataStore.length"
        @input="fetchPaymentPageList"
      />
      <b-pagination
        class="my-auto"
        v-model="currentPage"
        :current-page="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        @input="fetchPaymentPageList"
      ></b-pagination>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
::v-deep .custom-datepicker .xmx-input{
  height: 44px !important;
  padding-top: 4px;
  min-height: unset;
  border: 1px solid #DCDAD5;
  box-shadow: none;
  border: 1px solid #DCDAD5;
  border-radius: 12px;
}
.file_Upload{
    opacity: 0;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0px;
    width: 140px;
    z-index: 3000;
    cursor: pointer;
}
.bulk-upload-button{
    width: 120px!important;
    text-align: center;
    color: #B3AFAF;
    background-color: #252B3A;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    padding: 10px;
}
.media-body-custom{
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
  bottom:2px;
}
.btn-custom{
  border: 1px solid #252B3B;
  border-radius: 4px;
  background: #FFFCFC;
  color: #252B3B;
}
.btn-custom.disabled{
  pointer-events: none;
}
.search-button{
  background: #EAEAEA;
  border-color: #EAEAEA;
  color: #B3AFAF;
}
::v-deep .main-content {
    overflow: visible;
}
::v-deep  .datepicker-custom .reportrange-text{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
} 
.bulk-upload-button:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
    background-color: #191d26;
}
.file_Upload:hover + .bulk-upload-button{
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
    background-color: #191d26;
}
input#file-upload-button{
  cursor: pointer;
}
.btn-link {
  color: #5664d2;
}
.btn-link:hover {
  color: #07080b;
  text-decoration: none;
}
.down-btn button{
  height: 31px;
  position: relative;
  top: 4px;
  margin-left: 10px;
}
</style>